import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Logo from "../assets/bv.png";
import "./styles/Footer.css";

function Footer() {
  const year = new Date().getFullYear();
  return (
    <div className="footer">
      <div className="socialMedia">
        <a href="https://www.instagram.com/restaurantbellavita.inzlingen/?hl=en">
          <InstagramIcon />
        </a>
        <a href="https://www.google.com/maps/place/Restaurant+Bella+Vita/@47.5877362,7.6927988,21z/data=!4m16!1m9!3m8!1s0x4791b1b09bf5c60d:0x710b037bf9d3c41b!2sRestaurant+Bella+Vita!8m2!3d47.587776!4d7.6927673!9m1!1b1!16s%2Fg%2F11ld6vjzm2!3m5!1s0x4791b1b09bf5c60d:0x710b037bf9d3c41b!8m2!3d47.587776!4d7.6927673!16s%2Fg%2F11ld6vjzm2?entry=ttu">
          <LocationOnIcon />
        </a>
        <img className="img" src={Logo} />
      </div>
      <p> © {year} BellaVita</p>
    </div>
  );
}

export default Footer;
