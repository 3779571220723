import React from "react";
import { saveAs } from "file-saver";
import MenuPDF from "../assets/Menu.pdf";
import "./styles/PDFDownloader.css"; // Create this CSS file for styling

const PDFDownloader = () => {
  const downloadPDF = () => {
    // Trigger the download
    saveAs(MenuPDF, "Menu.pdf"); // Specify the desired file name
  };

  return (
    <div className="pdf-downloader-container">
      <button className="download-button" onClick={downloadPDF}>
        Menü herunterladen
      </button>
    </div>
  );
};

export default PDFDownloader;
