import React from "react";
import PizzaLeft from "../assets/pizzaLeft.jpg";
import "./styles/ContactPage.css";

function ContactPage() {
  return (
    <div className="contact">
      <div
        className="leftSide"
        style={{ backgroundImage: `url(${PizzaLeft})` }}
      ></div>
      <div className="rightSide">
        <h1> Kontaktiere uns</h1>

        <form className="contact-form">
          <p>Email: restaurantbellavita.inzlingen@gmail.com</p>
          <p>Telefon: 07621 9499999 </p>
          <a href="tel:+4976219499999" className="call-us-link">
            Rufen Sie uns an
          </a>
        </form>
      </div>
    </div>
  );
}

export default ContactPage;
