import React from "react";
import "./styles/RestaurantGallery.css"; // Import the CSS file
import Wine from "../assets/wine.jpg";
import Restaurant1 from "../assets/bellavitae.jpg";
import Restaurant2 from "../assets/bellavitae2.jpg";
import Restaurant3 from "../assets/bellavitae3.jpg";

const RestaurantGallery = () => {
  return (
    <div>
      {/* Wine photo at the top */}
      <img id="winePhoto" src={Wine} alt="Wine" />

      {/* Three photos of the restaurant */}
      <div id="restaurantPhotos">
        <img className="restaurantPhoto" src={Restaurant1} alt="Restaurant 1" />
        <img className="restaurantPhoto" src={Restaurant2} alt="Restaurant 2" />
        <img className="restaurantPhoto" src={Restaurant3} alt="Restaurant 3" />
      </div>
    </div>
  );
};

export default RestaurantGallery;
