import React from "react";
import Men1 from "../assets/menu.JPG";
import Menu2 from "../assets/menu2.JPG";
import PDFDownloader from "./PDFDownloader";
import "./styles/Menu.css";

function Menu() {
  return (
    <div className="menu">
      <h1 className="menuTitle">Unser Menü</h1>
      <div className="menuList">
        <div>
          <img className="imag" src={Men1} alt="Menu Photo" />
        </div>
        <div>
          <img className="imag" src={Menu2} alt="Menu Photo" />
        </div>
      </div>
      <PDFDownloader />
    </div>
  );
}

export default Menu;
