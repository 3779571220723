import React from "react";
import { Link } from "react-router-dom";
import BannerImage from "../assets/pizza.jpeg";
import "./styles/Home.css";

function Welcome() {
  return (
    <div className="home" style={{ backgroundImage: `url(${BannerImage})` }}>
      <div className="headerContainer">
        <h1>Herzlich willkommen</h1>
        <p>Erleben Sie die Aromen Italiens!</p>
        <Link to="/speisekarte">
          <button>Unsere Speisekarte</button>
        </Link>
      </div>
    </div>
  );
}

export default Welcome;
