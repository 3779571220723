import React from "react";
import Pasta from "../assets/pasta.jpg";
import "./styles/About.css";

function About() {
  return (
    <div className="about">
      <div
        className="aboutTop"
        style={{ backgroundImage: `url(${Pasta})` }}
      ></div>
      <div className="aboutBottom">
        <h1>Über uns</h1>
        <p>
          Willkommen bei Bella Vita, dem neuesten Juwel der italienischen
          Kulinarik in Inzlingen. Unser Restaurant ist mehr als nur ein Ort zum
          Essen - es ist eine Reise durch die Aromen und Leidenschaft der
          authentischen italienischen Küche. Der Name Bella Vita, was 'Schönes
          Leben' bedeutet, spiegelt die Essenz dessen wider, was wir bieten -
          eine einladende Atmosphäre, in der jedes Gericht eine Geschichte
          erzählt und jeder Moment ein Fest ist. Von den ersten Bissen unserer
          handgefertigten Pastagerichte bis zu den letzten Schlucken unserer
          sorgfältig ausgewählten Weine laden wir Sie ein, sich in den zeitlosen
          Charme der italienischen Gastronomie zu vertiefen. Unsere Hingabe an
          hochwertige Zutaten, traditionelle Rezepte und herzliche
          Gastfreundschaft gewährleisten, dass jeder Besuch bei Bella Vita zu
          einem unvergesslichen Erlebnis wird. Treten Sie ein und genießen Sie
          die Schönheit des Lebens, einen köstlichen Teller nach dem anderen.
          Herzlich willkommen bei Bella Vita in Inzlingen
        </p>
      </div>
    </div>
  );
}

export default About;
