import React from "react";
import Welcome from "./Welcom";
import About from "./About";
import RestaurantGallery from "./RestaurantGallery";

function Home() {
  return (
    <div>
      <Welcome />
      <About />
      <RestaurantGallery />
    </div>
  );
}

export default Home;
